<template>
<div class="portal-page">
  <a-layout>
    <!-- header -->
    <a-layout-header class="portal-header">
      <a-row>
        <a-col :lg="12" :xs="12">
          <a-space v-if="!device.mobile">
            <span class="header-logo">老玩客運動</span>
            <span class="header-beta">Beta</span>
          </a-space>

          <span :style="{ fontSize: '1.25rem', fontWeight: 500, letterSpacing: '0.05rem' }" v-else>
            老玩客運動
          </span>
        </a-col>

        <a-col :lg="12" :xs="12" align="right">
          <!-- <a-button
            class="mr-2"
            type="default"
            shape="round"
            :size="device.mobile ? 'medium' : 'large'"
            style="background-color:white; color:#212121; font-size:.9rem; font-weight:450; border-color:white;"
            @click="toDownloadPage"
          >
            下載 APP
          </a-button>
          <a-button
            type="default"
            shape="round"
            :size="device.mobile ? 'medium' : 'large'"
            style="background-color:#212121; color:white; font-size:.9rem; font-weight:450; border-color:#212121;"
            @click="toAuthLoginPage"
          >
            登入
          </a-button> -->
        </a-col>
      </a-row>
    </a-layout-header>
    <!-- header -->

    <!-- content -->
    <a-layout-content class="portal-content">
      <div class="content__jumbotron">
        <div class="title-wrapper">
          <div class="subtitle">一起動起來</div>
          <div class="title">再創熟齡自我價值</div>
        </div>

        <div class="title-actions">
          <a-row :gutter="45">
            <a-col :lg="12" :xs="24">
              <a-button
                class="mb-6"
                type="default"
                shape="round"
                style="background-color:white; color:#212121; font-size:1.45rem; font-weight:450; border-color:white; width:100%; height:70px;"
                @click="toDownloadPage"
              >
                下載 APP
              </a-button>
            </a-col>

            <a-col :lg="12" :xs="24">
              <a-button
                type="default"
                shape="round"
                style="background-color:#212121; color:white; font-size:1.45rem; font-weight:450; border-color:#212121; width:100%; height:70px;"
                @click="toAuthLoginPage"
              >
                登入
              </a-button>
            </a-col>
          </a-row>
        </div>
      </div>

      <!-- <div class="content__intro">
        <div class="intro-card">
          <a-row>
            <a-col :lg="8" :xs="24">
              <div class="intro-title">Grow professionally<br>with Co-op</div>
              <div class="intro-text">To grow professionally, any person can launch or join into the Coop. You can learn, earn, enjoy, help together.</div>
            </a-col>
            <a-col :lg="8" :xs="24"></a-col>
            <a-col :lg="8" :xs="24"></a-col>
          </a-row>
        </div>
      </div> -->
    </a-layout-content>
    <!-- content -->

    <!-- footer -->
    <!-- <footer class="portal-footer">
      <template v-if="env == 'production'">正式環境 v1.0</template>
      <template v-if="env == 'development'">測試環境 v1.0</template>
    </footer> -->
    <!-- footer -->

    <!-- footer -->
    <!-- <a-layout-footer class="portal-footer">
      <div class="footer__wrapper">
        <a-row>
          <a-col :lg="8" :xs="24">
            <div class="footer-logo">老玩客運動</div>
            <div class="footer-slogan">高齡運動課程領導者</div>
          </a-col>

          <a-col :lg="6" :xs="24"></a-col>
        </a-row>
      </div>
    </a-layout-footer> -->
    <!-- footer -->
  </a-layout>
</div>
</template>

<script>
import { defineComponent } from 'vue'
import { createDeviceDetector } from 'next-vue-device-detector'
import { DownloadOutlined } from '@ant-design/icons-vue'

export default defineComponent({
  components: {
    DownloadOutlined
  },
  setup() {
    const device = createDeviceDetector();

    return {
      device
    }
  },
  methods: {
    /* router */
    toDownloadPage() {
      this.$router.push({ name: 'Download' });
    },
    toAuthLoginPage() {
      this.$router.push({ name: 'AuthLogin' });
    }
  }
})
</script>

<style lang="scss">
.portal-page {
  .portal-header {
    background-color: #faf0e7;
    padding: 0 12px;
    height: 60px;

    /* md */
    @media screen and (min-width: 970px) {
      padding: 0 50px;
    }

    .header-logo {
      font-size: 1.5rem;
      font-weight: 500;
    }

    .header-beta {
      color: orange;
      font-size: 1rem;
      font-weight: 500;
    }
  }

  .portal-content {
    background: white;

    .content__jumbotron {
      background-color: #faf0e7;
      height: calc(100vh - 60px);
      position: relative;
      overflow: hidden;

      .title-wrapper {
        max-width: 1050px;
        margin: 0 auto;
        padding: 60px 12px;

        /* md */
        @media screen and (min-width: 970px) {
          padding: 90px 0;
        }

        .subtitle {
          color: #212121;
          font-size: 2.5rem;
          font-weight: bold;
          line-height: 3rem;
          text-align: center;
          letter-spacing: 0.125rem;
          margin-bottom: 12px;

          /* md */
          @media screen and (min-width: 970px) {
            font-size: 3.5rem;
            line-height: 5rem;
          }
        }

        .title {
          font-size: 3.5rem;
          font-weight: bold;
          line-height: 4.5rem;
          background: -webkit-linear-gradient(45deg, #09009f, #00ff95 80%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          letter-spacing: 0.15rem;
          text-align: center;

          /* md */
          @media screen and (min-width: 970px) {
            font-size: 4.5rem;
            line-height: 5rem;
          }
        }
      }

      .title-actions {
        padding: 0px 0;
        width: 80%;
        margin: 0 auto;

        /* md */
        @media screen and (min-width: 970px) {
          padding: 40px 0;
          width: 650px;
        }
      }
    }

    .content__intro {
      padding-bottom: 80px;
      background-color: #faf0e7;

      .intro-card {
        height: 300px;
        max-width: 1050px;
        margin: 0 auto;
        padding: 40px;
        background: white;
        border-radius: 8px;

        .intro-title {
          color: #212121;
          font-size: 2rem;
          font-weight: bold;
          line-height: 3rem;
          margin-bottom: 20px;
        }

        .intro-text {
          color: #424242;
          font-size: 1rem;
          font-weight: 500;
        }
      }
    }
  }

  .portal-footer {
    height: 48px;
    line-height: 48px;
    color: #757575;
    text-align: center;
    background: #faf0e7;
    // padding: 24px 24px;

    /* md */
    // @media screen and (min-width: 970px) {
    //   padding: 12px 50px;
    // }

    // .footer__wrapper {
    //   padding: 50px 0;
    //   max-width: 1050px;
    //   margin: 0 auto;
    // }

    .footer-logo {
      color: white;
      font-size: 1.5rem;
      font-weight: bold;
      margin-bottom: 12px;
      letter-spacing: 0.2rem;

      /* md */
      @media screen and (min-width: 970px) {
        font-size: 2rem;
      }
    }

    .footer-slogan {
      color: white;
      font-size: 1.4rem;
      font-weight: 500;
      letter-spacing: 0.1rem;
    }
  }
}
</style>